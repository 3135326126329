"use client";
import Image from 'next/image';
import Link from "next/link";
import embarcaLogoFull from '@/app/assets/embarca-ai-logo-full.svg';
import { Accordion, AccordionHeader, AccordionBody } from "@/components/MaterialTailwind/MTProvider";
import { SetStateAction, useState } from 'react';
import { BASE_URL, MONOLITH_LINK } from '@/lib/constants';

export default function Sitemap() {

    const sitemap = [
        {
            title: 'Institucional',
            links: [
                { title: 'Sobre nós', url: "https://www.embarca.ai/sobre-nos" },
                { title: 'Trabalhe conosco', url: "https://embarca.abler.com.br/" },
                { title: 'Seja nosso parceiro', url: `${MONOLITH_LINK}/seja-nosso-parceiro` },
                { title: 'Viações parceiras', url: "https://www.embarca.ai/parceiros"},
                { title: 'Blog', url: `https://blog.${BASE_URL}/` },
                { title: 'Embarca', url: `https://embarca.${BASE_URL}`},
                { title: 'Passagens Promocionais', url: `https://passagens.${BASE_URL}/passagens-promocionais`},
                { title: 'Regulamento de Promoções', url: "https://www.embarca.ai/regulamento-de-promocoes"},
                { title: 'Política de Privacidade', url: "https://www.embarca.ai/politica-de-privacidade"},
                { title: 'Termos e Condições', url: "https://www.embarca.ai/termos-de-uso"}
            ]
        },
        
        {
            title: 'Principais Rotas',
            links: [
                { title: 'Porto Alegre > Florianópolis', url: `https://passagens.${BASE_URL}/passagem-de-onibus/porto-alegre-rs/florianopolis-sc` },
                { title: 'Curitiba > Ponta Grossa', url: `https://passagens.${BASE_URL}/passagem-de-onibus/curitiba-pr/ponta-grossa-pr` },
                { title: 'Florianópolis > Porto Alegre', url: `https://passagens.${BASE_URL}/passagem-de-onibus/florianopolis-sc/porto-alegre-rs` },
                { title: 'Ponta Grossa > Curitiba', url: `https://passagens.${BASE_URL}/passagem-de-onibus/ponta-grossa-pr/curitiba-pr` },
                { title: 'Cascavel > Toledo', url: `https://passagens.${BASE_URL}/passagem-de-onibus/cascavel-pr/toledo-pr` },
                { title: 'Toledo > Cascavel', url: `https://passagens.${BASE_URL}/passagem-de-onibus/toledo-pr/cascavel-pr` },
                { title: 'Criciúma > Florianópolis', url: `https://passagens.${BASE_URL}/passagem-de-onibus/criciuma-sc/florianopolis-sc` },
                { title: 'Juiz de Fora > Ubá', url: `https://passagens.${BASE_URL}/passagem-de-onibus/juiz-de-fora-mg/uba-mg` },
                { title: 'Curitiba > Matinhos', url: `https://passagens.${BASE_URL}/passagem-de-onibus/curitiba-pr/matinhos-pr` },
                { title: 'Ubá > Juiz de Fora', url: `https://passagens.${BASE_URL}/passagem-de-onibus/uba-mg/juiz-de-fora-mg` },
                { title: 'Mais Rotas', url: `https://passagens.${BASE_URL}/passagem-de-onibus` },
            ]
        },

        {
            title: 'Principais operadoras',
            links: [
                { title: 'Viação Garcia', url: `https://viacaogarciapassagem.${BASE_URL}` },
                { title: 'Ouro e Prata', url: `https://ouroepratapassagem.${BASE_URL}` },
                { title: 'Viação Catedral', url: `https://catedralpassagem.${BASE_URL}` },
                { title: 'Viação Santo Anjo', url: `https://santoanjopassagem.${BASE_URL}` },
                { title: 'Viação Princesa dos Campos', url: `https://princesadoscampospassagem.${BASE_URL}` },
                { title: 'Viação Brasil Sul', url: `https://brasilsulpassagem.${BASE_URL}` },
                { title: 'Viação Graciosa', url: `https://graciosapassagem.${BASE_URL}` },
                { title: 'Viação Andorinha', url: `https://andorinhapassagem.${BASE_URL}` },
                { title: 'Viação Itapemirim', url: `https://itapemirimpassagem.${BASE_URL}` },
                { title: 'Viação Unida Mansur', url: `https://unidamansurpassagem.${BASE_URL}` },
                { title: 'Viação Guerino Seiscento', url: `https://guerinoseiscentopassagem.${BASE_URL}` },
                { title: 'Mais Viações', url: `https://${BASE_URL}/parceiros` },
            ]
        },

        {
            title: 'Principais destinos',
            links: [
                // { title: 'São Paulo - SP', url: "https://www.embarca.ai/passagem-de-onibus/sao-paulo-tiete-sp" },
                { title: 'Curitiba - PR', url: "https://www.embarca.ai/passagem-de-onibus/curitiba-pr" },
                { title: 'Porto Alegre - RS', url: "https://www.embarca.ai/passagem-de-onibus/porto-alegre-rs" },
                { title: 'Cuiabá - MT', url: "https://www.embarca.ai/passagem-de-onibus/cuiaba-mt" },
                { title: 'Brasília - DF', url: "https://www.embarca.ai/passagem-de-onibus/brasilia-df" },
                { title: 'Belo Horizonte - MG', url: "https://www.embarca.ai/passagem-de-onibus/belo-horizonte-mg" },
                { title: 'Ponta Grossa - PR', url: "https://www.embarca.ai/passagem-de-onibus/ponta-grossa-pr" },
                { title: 'Rio de Janeiro - RJ', url: "https://www.embarca.ai/passagem-de-onibus/rio-de-janeiro-rj" },
                { title: 'Goiânia - GO', url: "https://www.embarca.ai/passagem-de-onibus/goiania-go" },
                { title: 'Campo Grande - MS', url: "https://www.embarca.ai/passagem-de-onibus/campo-grande-ms" },
                { title: 'Mais Destinos', url: "https://www.embarca.ai/passagem-de-onibus/" },
            ]
        },
        {
            title: 'Rodoviárias',
            links: [
                // { title: 'São Paulo - Tiete', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/sao-paulo-tiete" },
                // { title: 'São Paulo - Barra Funda', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/sao-paulo-barra-funda" },
                { title: 'Rio de Janeiro - Novo Rio', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/rio-de-janeiro-rj" },
                { title: 'Belo Horizonte', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/belo-horizonte-mg" },
                { title: 'Curitiba', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/curitiba-pr" },
                { title: 'Passo Fundo', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/passo-fundo-rs" },
                { title: 'Ponta Grossa', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/ponta-grossa-pr" },
                { title: 'Criciúma', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/criciuma-sc" },
                { title: 'Porto Alegre', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/porto-alegre-rs" },
                // { title: 'Toledo', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria/toledo" },
                { title: 'Mais Rodoviárias', url: "https://www.embarca.ai/passagem-de-onibus/rodoviaria" },
            ]
        },
        {
            title: 'Ajuda',
            links: [
                { title: 'Fale conosco', url: "https://www.embarca.ai/fale-conosco" },
                { title: 'Dúvidas frequentes', url: "https://www.embarca.ai/faq" },
                { title: 'Troca e Remarcação', url: "https://www.embarca.ai/troca-e-remarcacao" },
            ]
        }
    ];
    return (
        <section className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:px-[calc(50vw-36rem)] px-4 py-8">
            {/* div com logo */}
            <div className="pr-16">
                <div className="mb-5">
                    <Image src={embarcaLogoFull} alt="embarcaLogo" />
                </div>
                <p className="font-thin text-xs">A compra da sua passagem de ônibus no Embarca.ai é feita com total segurança!
                    Utilizamos diversas ferramentas tecnológicas para proteger os seus dados pessoais.
                    Além disso, trabalhamos apenas com viações parceiras legalizadas para garantir que você faça
                    uma viagem segura.</p>
                <div className="flex gap-5 items-center py-5">
                    <Link target="_blank" href="https://www.instagram.com/embarca.ai/">
                        <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Instagram da Embarca.ai</title>
                            <path d="M7.632 2.846a4.748 4.748 0 0 0-4.743 4.743v7.589a4.748 4.748 0 0 0 4.743 4.743h7.589a4.748 4.748 0 0 0 4.743-4.743v-7.59a4.748 4.748 0 0 0-4.743-4.742H7.63Zm0 1.897h7.589a2.85 2.85 0 0 1 2.846 2.846v7.589a2.85 2.85 0 0 1-2.846 2.845H7.63a2.85 2.85 0 0 1-2.845-2.845v-7.59a2.85 2.85 0 0 1 2.846-2.845Zm8.537.949a.948.948 0 1 0 0 1.896.948.948 0 0 0 0-1.896Zm-4.743.948a4.748 4.748 0 0 0-4.743 4.743 4.748 4.748 0 0 0 4.743 4.743 4.748 4.748 0 0 0 4.743-4.743 4.748 4.748 0 0 0-4.743-4.743Zm0 1.897a2.85 2.85 0 0 1 2.846 2.846 2.85 2.85 0 0 1-2.846 2.846 2.85 2.85 0 0 1-2.846-2.846 2.85 2.85 0 0 1 2.846-2.846Z" fill="#2D2D2D" />
                        </svg>
                    </Link>
                    <Link target="_blank" href="https://www.facebook.com/EmbarcaAI/">
                        <svg width="24" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Facebook da Embarca.ai</title>
                            <path d="M12.193 1.897c-5.228 0-9.486 4.259-9.486 9.487 0 5.227 4.258 9.486 9.486 9.486 5.228 0 9.486-4.259 9.486-9.486 0-5.228-4.258-9.487-9.486-9.487Zm0 1.898a7.575 7.575 0 0 1 7.589 7.589 7.567 7.567 0 0 1-6.433 7.492v-5.23h2.209l.346-2.244H13.35v-1.226c0-.933.304-1.76 1.177-1.76h1.4v-1.96c-.246-.033-.767-.106-1.75-.106-2.055 0-3.26 1.086-3.26 3.558v1.493H8.805v2.244h2.113v5.212a7.568 7.568 0 0 1-6.313-7.474 7.575 7.575 0 0 1 7.59-7.59Z" fill="#2D2D2D" />
                        </svg>
                    </Link>
                    <Link target="_blank" href="https://www.youtube.com/channel/UCr-vNfAUNUuqGnl5hG1aDOQ">
                        <svg width="24" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Youtube da Embarca.ai</title>
                            <path d="M11.96 3.795s-5.933 0-7.413.396A2.375 2.375 0 0 0 2.87 5.868c-.396 1.48-.396 5.516-.396 5.516s0 4.036.396 5.515a2.374 2.374 0 0 0 1.677 1.677c1.48.397 7.413.397 7.413.397s5.933 0 7.413-.397a2.376 2.376 0 0 0 1.676-1.677c.397-1.48.397-5.515.397-5.515s0-4.036-.397-5.516a2.376 2.376 0 0 0-1.676-1.677c-1.48-.396-7.413-.396-7.413-.396Zm0 1.897c2.734 0 6.157.127 6.922.332.16.042.291.174.335.335.229.852.332 3.452.332 5.025 0 1.573-.103 4.172-.332 5.024a.482.482 0 0 1-.335.336c-.764.205-4.188.332-6.922.332-2.733 0-6.157-.127-6.922-.332a.482.482 0 0 1-.336-.335c-.228-.852-.331-3.452-.331-5.025 0-1.573.103-4.173.331-5.027a.48.48 0 0 1 .336-.333c.763-.205 4.188-.332 6.922-.332Zm-1.898 2.405v6.574l5.692-3.287-5.691-3.287Z" fill="#2D2D2D" />
                        </svg>
                    </Link>
                    <Link target="_black" href="https://www.tiktok.com/@embarca.ai">
                        <svg width="23" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <title>Tiktok da Embarca.ai</title>
                            <path d="M5.843 2.75a2.764 2.764 0 0 0-2.75 2.75v11a2.764 2.764 0 0 0 2.75 2.75h11a2.764 2.764 0 0 0 2.75-2.75v-11a2.764 2.764 0 0 0-2.75-2.75h-11Zm0 1.833h11c.518 0 .916.4.916.917v11a.902.902 0 0 1-.916.917h-11a.902.902 0 0 1-.917-.917v-11c0-.518.399-.917.917-.917Zm5.5 1.834v6.416a.902.902 0 0 1-.917.917.902.902 0 0 1-.917-.917c0-.518.4-.916.917-.916v-1.834a2.764 2.764 0 0 0-2.75 2.75 2.764 2.764 0 0 0 2.75 2.75 2.764 2.764 0 0 0 2.75-2.75V9.38c.565.402 1.154.703 1.833.703V8.25c-.043 0-.675-.2-1.117-.585-.441-.385-.716-.868-.716-1.248h-1.833Z" fill="#2D2D2D" />
                        </svg>
                    </Link>
                </div>
            </div>
            {sitemap.map((item, index) => (
                SitemapItemDesktop(index, item)
            ))}
            {sitemap.map((item, index) => (
                SitemapItemMobile(index, item)
            ))}
        </section>)
}

function SitemapItemDesktop(index: number, item: { title: string; links: { title: string; url: string; }[]; }) {
    return <div key={index} className="hidden md:block">
        <h3 className="uppercase text-xs">{item.title}</h3>
        <ul className="font-extralight mb-5">
            {item.links.map((link, index) => (
                <li key={index} className="my-1"><Link href={link.url}>{link.title}</Link></li>
            ))}
        </ul>
    </div>;
}

function SitemapItemMobile(index: number, item: { title: string; links: { title: string; url: string; }[]; }) {

    const [open, setOpen] = useState(-1);
    const handleOpen = (value: SetStateAction<number>) => setOpen(open === value ? -1 : value);

    return <Accordion open={open === index} key={index} icon={<Icon id={index} open={open} />} className="block md:hidden">
        <AccordionHeader onClick={() => handleOpen(index)} className="uppercase text-xs hover:text-gray-5">{item.title}</AccordionHeader>
        <AccordionBody>
            <ul className="font-light">
                {item.links.map((link, index) => (
                    <li key={index} className="my-3"><Link href={link.url}>{link.title}</Link></li>
                ))}
            </ul>
        </AccordionBody>
    </Accordion>;
}

function Icon({ id, open }: { id: number, open: number }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}